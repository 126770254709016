<template>
  <div>
    <div class="placard">
      <div class="mainBox">
        <div class="num">Proposal #1895122</div>
        <el-row>
          <el-col
            :xs="24"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="4"
            class="cellItem"
            v-for="item in 30"
            :key="item"
          >
            <div class="productItem" @click="viewDetail()">
              <div class="index">
                {{ item }}
              </div>
              <div class="img">
                <img
                  src="https://pyk.a2z-deal.com/uploads/20240618/15/5424-1224_thumb_P_1675316219674.jpg"
                  alt=""
                />
              </div>
              <div class="price">$1.59 — $1.99</div>
              <div class="name">1.5 oz Shot Glass</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <Details ref="detailsDialog"></Details>
    </div>
  </div>
</template>

<script>
import Details from "./details.vue";
import {createCatalogue} from '@/api/placard'
export default {
  components: {
    Details,
  },
  data() {
    return {
      prodictId: "",
    };
  },
  mounted(){
    createCatalogue({
      ids:this.$route.query.ids,
      token:localStorage.getItem('token'),
      percent:this.$route.query.percent,
      quantity:this.$route.query.quantity.split(',') 
    }).then(res=>{
      //console.log(res)
    })
  },
  methods: {
    handleClose(done) {
      done();
    },
    viewDetail() {
      this.dialogVisible = true;
      this.$refs.detailsDialog.id= "818";
      this.$refs.detailsDialog.dialogVisible= true;
      this.$refs.detailsDialog.getproductDetails();
    },
  },
};
</script>


<style lang="scss" scoped>
.placard {
  background-color: rgb(250 250 250);
  padding-top: 50px;
  .mainBox {
    // margin-top: 60px !important;
    width: 1170px;
    height: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    background-color: rgb(255 255 255);
    padding: 80px 40px;
    .num {
      margin-left: 20px;
    }
    .productItem {
      border: 1px solid #f0f0f0;
      margin: 10px;
      padding: 20px 40px;
      cursor: pointer;
      .index {
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .price {
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        line-height: 50px;
      }
      .name {
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-weight: 800;
        line-height: 50px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
<style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-6 {
    width: 25%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-6 {
    width: 33.3%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .el-col-lg-6 {
    width: 50%;
  }
}
</style>
