<template>
  <el-dialog
    title="商品"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
  >
    <div class="xtx-goods-page" style="margin-bottom: 100px; margin-top: 30px">
      <div class="container">
        <!-- 商品信息 -->
        <div class="info-container">
          <div>
            <div
              class="goods-info"
              style="margin-right: 30px; margin-left: 30px"
            >
              <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <!-- 图片预览区 -->
                  <pic-zoom
                    :width="527"
                    :url="productInfo.images[0]"
                    :scale="3"
                    :pointer="true"
                    overlayStyle="width: 100%;height: 100%"
                  ></pic-zoom>
                </el-col>
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                  style="border: 1px solid #e0e0e0; padding: 20px"
                >
                  <div class="detailsInfo">
                    <div class="topbox" style="margin-bottom: 20px">
                      <span class="name">{{ productInfo.name }}</span>
                      <span class="num" style="margin-left: 20px">{{
                        productInfo.code
                      }}</span>
                    </div>
                    <div class="description">
                      {{ productInfo.describe }}
                    </div>
                    <div class="price">
                      <div class="title">ENTER YOUR QUANTITY</div>
                      <el-table
                        style="width: 100%"
                        :data="getValues"
                        :cell-style="cellStyle"
                        border
                      >
                        <el-table-column
                          v-for="(item, index) in getHeaders"
                          :key="index"
                          :prop="item"
                          :show-header="false"
                        >
                        </el-table-column>
                      </el-table>
                      <div style="margin-top: 10px; float: right">
                        <el-input-number
                          style="float: left"
                          v-model="num"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                        ></el-input-number>
                        <div class="unitprice">× $1.05 EA</div>
                      </div>
                    </div>
                    <div class="material">
                      <div class="title">METERIAL</div>
                      <div class="content">
                        {{ productInfo.material_str }}
                      </div>
                    </div>
                    <div class="printing-mode">
                      <div class="title">PRINTING MODE</div>
                      <div class="content">{{ productInfo.imprint_str }}</div>
                    </div>
                    <div class="printing-size">
                      <div class="title">PRINTING SIZE</div>
                      <div class="content">{{ productInfo.imprint_area }}</div>
                    </div>
                    <div class="color">
                      <div class="title">COLOR</div>
                      <div class="content">{{ productInfo.color }}</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
import PicZoom from "../product/components/PicZoom.vue";
import { productDetails } from "@/api/product";
export default {
  components: { PicZoom },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
        dialogVisible: false,
      productInfo: {},
      // url: require("./110517_1.webp"),
      headers: [
        {
          prop: "QTY",
          label: "QTY",
        },
        {
          prop: "PRICE",
          label: "PRICE",
        },
      ],
      tableData: [
        {
          QTY: "200+",
          PRICE: "$1.16",
        },
        {
          QTY: "500+",
          PRICE: "$1.05",
        },
        {
          QTY: "1,000+",
          PRICE: "$1.00",
        },
        {
          QTY: "5,000+",
          PRICE: "$0.96",
        },
      ],
      num: "",
      //   id: "",
    };
  },
  mounted() {},
  computed: {
    getHeaders() {
      return this.tableData.reduce(
        (pre, cur, index) => pre.concat(`value${index}`),
        ["title"]
      );
    },
    getValues() {
      return this.headers.map((item) => {
        return this.tableData.reduce(
          (pre, cur, index) =>
            Object.assign(pre, { ["value" + index]: cur[item.prop] }),
          { title: item.label }
        );
      });
    },
  },
  methods: {
    getproductDetails() {
      productDetails({ id: this.id }).then((res) => {
        this.productInfo = res.data;
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // //console.log(row, column, rowIndex, columnIndex);
      if (columnIndex == 2) {
        // 返回样式对象
        return { backgroundColor: "#c5e8c8" };
      }
      // 或者返回空对象，表示不应用任何样式
      return {};
    },
    handleChange(value) {
      // //console.log(value);
    },
  },
};
</script>
  <style scoped lang='scss'>
.xtx-goods-page {
  .container {
    max-width: 1330px;
    margin: 0 auto;
  }
  .goods-info {
    min-height: 600px;
    background: #fff;
    .detailsInfo {
      .name {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
      }
      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
      }
      .price {
        margin-top: 20px;
        margin-bottom: 30px;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
        }
        .el-table .old-row {
          background-color: #f0f9eb; /* 需要更改的颜色 */
        }
        ::v-deep .el-table__header-wrapper {
          display: none;
        }
        .unitprice {
          float: left;
          height: 30px;
          background-color: #dde2e4;
          line-height: 30px;
          width: 90px;
          padding: 5px 10px;
        }
      }
      .material {
        clear: both;
        margin-top: 20px;
        .title {
          font-weight: bold;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .printing-mode {
        margin-top: 20px;
        .title {
          font-weight: bold;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .printing-size {
        margin-top: 20px;
        .title {
          font-weight: bold;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
      .color {
        margin-top: 20px;
        .title {
          font-weight: bold;
        }
        .content {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
    .media {
      height: 527px;
    }

    .spec {
      border: 1px solid #666;
      height: 500px;
    }
  }

  .goods-tabs {
    min-height: 600px;
    background: #fff;
  }

  .goods-warn {
    min-height: 600px;
    background: #fff;
    margin-top: 20px;
  }

  .number-box {
    display: flex;
    align-items: center;

    .label {
      width: 60px;
      color: #999;
      padding-left: 10px;
    }
  }

  .g-name {
    font-size: 22px;
  }

  .g-desc {
    color: #999;
    margin-top: 10px;
  }

  .g-price {
    margin-top: 10px;

    span {
      &::before {
        content: "¥";
        font-size: 14px;
      }

      &:first-child {
        //color: $priceColor;
        margin-right: 10px;
        font-size: 22px;
      }

      &:last-child {
        color: #999;
        text-decoration: line-through;
        font-size: 16px;
      }
    }
  }

  .g-service {
    background: #f5f5f5;
    width: 500px;
    padding: 20px 10px 0 10px;
    margin-top: 10px;

    dl {
      padding-bottom: 20px;
      display: flex;
      align-items: center;

      dt {
        width: 50px;
        color: #999;
      }

      dd {
        color: #666;

        &:last-child {
          span {
            margin-right: 10px;

            &::before {
              content: "•";
              //color: $xtxColor;
              margin-right: 2px;
            }
          }

          a {
            //color: $xtxColor;
          }
        }
      }
    }
  }

  .goods-sales {
    display: flex;
    width: 400px;
    align-items: center;
    text-align: center;
    height: 140px;

    li {
      flex: 1;
      position: relative;

      ~ li::after {
        position: absolute;
        top: 10px;
        left: 0;
        height: 60px;
        border-left: 1px solid #e4e4e4;
        content: "";
      }

      p {
        &:first-child {
          color: #999;
        }

        &:nth-child(2) {
          //color: $priceColor;
          margin-top: 10px;
        }

        &:last-child {
          color: #666;
          margin-top: 10px;

          i {
            //color: $xtxColor;
            font-size: 14px;
            margin-right: 2px;
          }

          &:hover {
            //color: $xtxColor;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.goods-tabs {
  min-height: 600px;
  background: #fff;

  nav {
    height: 70px;
    line-height: 70px;
    display: flex;
    border-bottom: 1px solid #f5f5f5;

    a {
      padding: 0 40px;
      font-size: 18px;
      position: relative;

      > span {
        //color: $priceColor;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.goods-detail {
  padding: 40px;

  .attrs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 10px;
      width: 50%;

      .dt {
        width: 100px;
        color: #999;
      }

      .dd {
        flex: 1;
        color: #666;
      }
    }
  }

  > img {
    width: 100%;
  }
}

.btn {
  margin-top: 20px;
}

.bread-container {
  padding: 25px 0;
}
</style>
  <style>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
  <style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-12 {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-12 {
    /* width: 33.3%; */
  }
}
@media screen and (max-width: 800px) {
  .el-col-lg-12 {
    width: 100%;
  }
}
::v-deep .el-dialog{
    margin-top: 10px !important;
}
</style>
  